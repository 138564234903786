<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <h1>Manage Add/Edit Customer</h1>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" :md="openSection ? '9' : '12'">
        <validation-observer ref="customerFormRef" #default="{ invalid }">
          <b-card>
            <b-tabs v-model="activeTab" fill>
              <b-tab title="Information" active>
                <!-- <b-row v-if="$route.query.id">
                  <b-col class="text-right">
                    <b-button
                      title="InSight Panel"
                      variant="flat-dark"
                      class="btn-icon rounded-circle"
                      @click="openSection = !openSection"
                    >
                      <feather-icon
                        style="width: 25px; height: 25px"
                        icon="SearchIcon"
                      />
                    </b-button>
                  </b-col>
                </b-row> -->
                <b-form @submit.prevent="onSubmit">
                  <b-row>
                    <b-col cols="12" md="3">
                      <b-row>
                        <b-col>
                          <b-form-group
                            label="Upload File"
                            label-for="onFileUpload"
                          >
                            <b-form-file
                              accept="image/*"
                              v-model="onFileUpload"
                              placeholder="Choose/Drop a file here..."
                              drop-placeholder="Drop a file here..."
                              id="onFileUpload"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row v-if="formData.base64 || formData.image_url">
                        <b-col class="text-center">
                          <b-form-group>
                            <b-img
                              style="margin-bottom: 5px"
                              height="250"
                              width="250"
                              thumbnail
                              fluid
                              :src="getImageSource"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row v-if="formData.base64 || formData.image_url">
                        <b-col class="text-center">
                          <b-form-group>
                            <b-button
                              @click="onFileRemove"
                              type="button"
                              variant="outline-danger"
                              size="sm"
                            >
                              Remove
                            </b-button>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>

                    <b-col cols="12" md="9">
                      <b-row>
                        <b-col>
                          <b-form-group label-for="v-company_id">
                            <label for="">
                              Company ID<span class="text-danger">*</span>
                            </label>
                            <validation-provider
                              #default="{ errors }"
                              name="Company ID"
                              rules="required"
                            >
                              <b-form-input
                                maxlength="15"
                                v-model="formData.company_id"
                                id="v-company_id"
                                :state="errors.length > 0 ? false : null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                              <small
                                class="text-danger"
                                v-if="getCompanyIDLength"
                              >
                                The Company ID field reaches character length
                              </small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group
                            label="Company Type"
                            label-for="v-company_type"
                          >
                            <v-select
                              id="v-company_type"
                              v-model.number="formData.company_type"
                              :options="companyTypes"
                              :reduce="(companyTypes) => companyTypes.name"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group
                            label="Active / Archived"
                            label-for="v-status"
                          >
                            <b-form-select
                              v-model="formData.company_status"
                              :options="statusOptions"
                              id="v-status"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-form-group
                            label="Company Name"
                            label-for="v-company"
                          >
                            <b-form-input
                              maxlength="120"
                              required
                              v-model="formData.company_name"
                              id="v-company"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-form-group
                            label="UEN No."
                            label-for="v-registration_code"
                          >
                            <b-form-input
                              maxlength="60"
                              v-model="formData.registration_code"
                              id="v-registration_code"
                              name="random-text"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group
                            label="GST Registration Code"
                            label-for="v-gst_registration_code"
                          >
                            <b-form-input
                              maxlength="60"
                              v-model="formData.gst_registration_code"
                              id="v-gst_registration_code"
                              name="random-text"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <h2>Billing Address</h2>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Building/Block/House No. and Street Name"
                        label-for="v-addr1"
                      >
                        <b-input-group class="input-group-merge">
                          <b-form-input
                            maxlength="60"
                            v-model="formData.addr1"
                            id="v-addr1"
                          />
                          <b-input-group-append
                            class="cursor-pointer"
                            is-text
                            @click="isShowingMap = !isShowingMap"
                          >
                            <feather-icon class="pin" icon="MapPinIcon" />
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Unit No. and Building Name"
                        label-for="v-addr2"
                      >
                        <b-form-input
                          maxlength="60"
                          v-model="formData.addr2"
                          id="v-addr2"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group label="City" label-for="v-city">
                        <b-form-input
                          maxlength="60"
                          v-model="formData.city"
                          id="v-city"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="State" label-for="v-state">
                        <b-form-input
                          maxlength="60"
                          v-model="formData.state"
                          id="v-state"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Region" label-for="v-region">
                        <b-form-input
                          maxlength="60"
                          v-model="formData.region"
                          id="v-region"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group label="Country" label-for="v-company_name">
                        <v-select
                          v-model="formData.country"
                          :options="
                            $store.getters['hbaseGeneral/getAllCountries']
                          "
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Postal" label-for="v-postal">
                        <b-form-input
                          maxlength="60"
                          v-model="formData.postal"
                          id="v-postal"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <ModalMap
                        :showMap="isShowingMap"
                        @onChangePlace="(v) => onGetPlace(v)"
                      />
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <h2>Other Information</h2>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group label="Phone 1" label-for="v-telephone_1">
                        <b-form-input
                          maxlength="60"
                          v-model="formData.telephone_1"
                          id="v-telephone_1"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Phone 2" label-for="v-telephone_2">
                        <b-form-input
                          maxlength="60"
                          v-model="formData.telephone_2"
                          id="v-telephone_2"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Website" label-for="v-website">
                        <b-form-input
                          maxlength="60"
                          v-model="formData.website"
                          id="v-website"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label-for="v-email">
                        <label for="">
                          Email<span class="text-danger">*</span>
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Email"
                          rules="required|email"
                        >
                          <b-form-input
                            id="v-email"
                            maxlength="60"
                            v-model="formData.email"
                            :state="errors.length > 0 ? false : null"
                            type="email"
                            placeholder="Email"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" md="6">
                      <b-form-group label="Currency" label-for="v-currency_id">
                        <b-form-select
                          v-model="formData.currency_id"
                          :options="currencies"
                          id="v-currency_id"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group label="GST" label-for="v-gst">
                        <b-form-select
                          @change="onSelectGST($event)"
                          v-model="formData.gst"
                          :options="gsts"
                          id="v-gst"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <span v-if="$route.query.id">
                    <b-row>
                      <b-col>
                        <h2>Company Locations</h2>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col>
                        <b-form-group>
                          <CompanyLocations
                            :header-id="headerId"
                            :data-source="dataSourceCompanyLocations"
                            @on-load="(v) => loadCompany()"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col>
                        <h2>Company Contacts</h2>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col>
                        <b-form-group>
                          <CompanyContacts
                            :header-id="headerId"
                            :data-source="dataSourceCompanyContacts"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col>
                        <h2>Internal Notes</h2>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col>
                        <b-form-group>
                          <quill-editor
                            ref="quillEditorRef"
                            v-model="content"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </span>

                  <b-row>
                    <b-col class="text-right">
                      <b-button
                        type="button"
                        class="mr-1"
                        variant="outline-danger"
                        @click="goBack()"
                      >
                        Cancel
                      </b-button>
                      <b-button
                        :disabled="invalid"
                        type="submit"
                        variant="primary"
                      >
                        {{ $route.query.id ? 'Update' : 'Create' }}
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </b-tab>
              <span v-if="$route.query.id">
                <b-tab title="Notes">
                  <Notes
                    ref="notesRef"
                    module="company"
                    :header-id="parseInt(formData.id)"
                  />
                </b-tab>
                <b-tab title="Documents">
                  <Documents
                    ref="documentsRef"
                    module="company"
                    :header-id="parseInt(formData.id)"
                  />
                </b-tab>
                <b-tab title="Logs">
                  <Logs
                    ref="logsRef"
                    module="company"
                    :header-id="parseInt(formData.id)"
                  />
                </b-tab>
                <b-tab title="Comments">
                  <Comments
                    ref="commentsRef"
                    module="company"
                    :header-id="parseInt(formData.id)"
                  />
                </b-tab>
              </span>
            </b-tabs>
          </b-card>
        </validation-observer>
      </b-col>

      <b-col cols="12" :md="openSection ? '3' : '12'" v-if="openSection">
        <b-row>
          <b-col>
            <CompanyInsightPanel
              :header-id="parseInt(formData.id)"
              @close-panel="(v) => (openSection = v)"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card>
              <b-tabs fill>
                <b-tab active>
                  <template #title>
                    <small>Comments</small>
                  </template>
                  <CompanyCommentsPanel
                    :header-id="parseInt(formData.id)"
                    :data-source="formData"
                  />
                </b-tab>
                <b-tab>
                  <template #title>
                    <small>Logs</small>
                  </template>
                  <CompanyLogsPanel :header-id="parseInt(formData.id)" />
                </b-tab>
                <b-tab>
                  <template #title>
                    <small>Approval</small>
                  </template>
                  <CompanyApproval :header-id="parseInt(formData.id)" />
                </b-tab>
              </b-tabs>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { required } from '@validations';
import companiesApi from '@api/companies';
import gstsApi from '@api/gsts';
import currenciesApi from '@api/currencies';
import companyTypesApi from '@api/company_types';
import statusesApi from '@api/statuses';
import { queryParameters } from '@/schema';

import CompanyLocations from './components/CompanyLocations';
import CompanyContacts from './components/CompanyContacts';
import CompanyInsightPanel from './components/CompanyInsightPanel';
import CompanyDocuments from './components/CompanyDocuments';
import CompanyNotes from './components/CompanyNotes';
import CompanyLogs from './components/CompanyLogs';
import CompanyComments from './components/CompanyComments';
import CompanyCommentsPanel from './components/CompanyCommentsPanel';
import CompanyLogsPanel from './components/CompanyLogsPanel';
import CompanyApproval from './components/CompanyApproval';

export default {
  name: 'CustomersManage',
  components: {
    CompanyLocations,
    CompanyContacts,
    CompanyInsightPanel,
    CompanyDocuments,
    CompanyNotes,
    CompanyLogs,
    CompanyComments,
    CompanyCommentsPanel,
    CompanyLogsPanel,
    CompanyApproval,
  },
  data: () => ({
    required,
    activeTab: 0,
    isShowingMap: false,
    openSection: false,
    onFileUpload: null,
    content: null,
    formData: {
      id: null,
      company_id: null,
      company_name: null,
      company_type: null,
      company_status: 'Approved',
      company_category: null,
      attn: null,
      addr1: null,
      addr2: null,
      postal: null,
      country: 'Singapore',
      city: 'Singapore',
      state: 'Singapore',
      region: 'Singapore',
      latitude: null,
      longitude: null,
      telephone_1: null,
      telephone_2: null,
      fax: null,
      website: null,
      email: null,
      logo: null,
      currency_id: 'SGD',
      registration_code: null,
      gst_registration_code: null,
      gst: null,
      gst_percent: null,
      employee_id: 0,
      notes: null,
      status: 'O',

      base64: null,
    },
    dataSourceCompanyLocations: [],
    dataSourceCompanyContacts: [],
    dataSourceCompanyDocuments: [],
    dataSourceCompanyNotes: [],
    currencies: [],
    gsts: [],
    statuses: [],
    statusOptions: [],
    companyTypes: [],
    headerId: 0,
  }),
  watch: {
    onFileUpload(file) {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.formData.logo = file.name;
          this.formData.base64 = reader.result;
        };
      }
    },
    activeTab(index) {
      if (index === 1) {
        this.$refs.notesRef.loadNotes();
      }

      if (index === 2) {
        this.$refs.documentsRef.loadDocuments();
      }

      if (index === 3) {
        // this.$refs.companyServiceRequestsRef.loadServiceRequest();
      }

      if (index === 3) {
        this.$refs.logsRef.loadLogs();
      }

      if (index === 4) {
        this.$refs.commentsRef.loadTimelineHeader();
      }
    },
  },
  computed: {
    getImageSource: {
      get() {
        if (this.$route.query.id) {
          if (this.formData.base64) {
            return this.formData.base64;
          }

          if (!this.formData.base64 && this.formData.image_url) {
            return this.formData.image_url;
          }
        }

        return this.formData.base64;
      },
    },
    getCompanyIDLength: {
      get() {
        if (this.formData.company_id) {
          return this.formData.company_id.length === 15;
        }
      },
    },
  },
  created() {
    this.loadGSTs();
    this.loadCurrencies();
    this.loadCompanyTypes();
    this.loadSystemCode();

    if (this.$route.query.id) {
      this.loadCompany();
    }
  },
  methods: {
    loadCompany() {
      companiesApi
        .get(this.$route.query.id)
        .then(({ data }) => {
          if (data.logo != '') {
            data.image_url = `${process.env.VUE_APP_ROOT_STATIC_URL}/company/${data.id}/${data.logo}`;
          }

          const { id, company_locations } = data;

          this.headerId = parseInt(id);

          if (company_locations.data) {
            company_locations.data.forEach((location) => {
              location.address = location.addr1;

              if (location.addr2) {
                location.address += ` ${location.addr2}`;
              }

              if (location.country) {
                location.address += ` ${location.country}`;
              }

              if (location.postal) {
                location.address += ` ${location.postal}`;
              }
            });

            this.dataSourceCompanyLocations = company_locations.data;
          }

          if (data.company_contacts.data) {
            this.dataSourceCompanyContacts = data.company_contacts.data;
          }

          if (data.company_documents.data) {
            this.dataSourceCompanyDocuments = data.company_documents.data;
          }

          if (data.company_notes.data) {
            this.dataSourceCompanyNotes = data.company_notes.data;
          }

          const { quill } = this.$refs.quillEditorRef;
          if (quill) {
            quill.root.innerHTML = data.notes;
          }

          const currency = this.currencies.find((x) => {
            return +x.item.id === data.currency_id;
          });

          data.currency_id = currency ? currency.item.name : 0;
          this.formData = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadGSTs() {
      const params = {
        sort: '-date_from',
        page: queryParameters.page,
        status: queryParameters.status,
      };

      gstsApi
        .list(params)
        .then(({ data }) => {
          const items = [];
          for (const item of data) {
            const { description, percent } = item;
            const descriptionWithPercent = `${description} - ${percent}%`;
            items.push({
              value: item.name,
              text: descriptionWithPercent,
              percent,
              item,
            });
          }

          if (!this.formData.gst) {
            this.formData.gst = items[0].value;
            this.formData.gst_percent = items[0].percent;
          }

          this.gsts = items;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadCurrencies() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      currenciesApi
        .list(params)
        .then(({ data }) => {
          const items = [];
          for (const item of data) {
            items.push({ value: item.name, text: item.description, item });
          }

          this.currencies = items;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadCompanyTypes() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      companyTypesApi
        .list(params)
        .then(({ data }) => {
          const items = [];
          for (const item of data) {
            items.push({
              name: item.name,
              label: `${item.name} - ${item.description}`,
            });
          }

          this.companyTypes = items;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadSystemCode() {
      const params = {
        sort: queryParameters.sort,
        page: { size: 1000 },
        status: queryParameters.status,
      };

      statusesApi
        .list(params)
        .then(({ data }) => {
          if (data != null) {
            const items = [];

            for (const item of data) {
              const { code: value, name: text, selections } = item;
              items.push({ value, text, selections });
            }

            this.statuses = items;

            const coystatus = items.find((x) => {
              return x.value === 'coystatus';
            });
            if (coystatus) {
              const { selections } = coystatus;
              this.statusOptions = selections;
            }
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    goBack() {
      this.$router.push('/pages/masterlists/customers');
    },
    onSubmit() {
      let app;

      const currency = this.currencies.find((x) => {
        return x.item.name === this.formData.currency_id;
      });

      const gst = this.gsts.find((x) => {
        return x.item.name === this.formData.gst;
      });

      this.formData.currency_id = currency ? parseInt(currency.item.id) : 0;
      this.formData.gst_percent = gst ? parseInt(gst.item.percent) : 0;
      this.formData.notes = this.content;

      if (this.formData.id) {
        app = companiesApi.update(this.formData);
      } else {
        app = companiesApi.add(this.formData);
      }

      app
        .then(({ data }) => {
          const msg = `Company: ${this.formData.company_name} success save`;
          this.toastConfig(msg);

          const url = `/pages/masterlists/customers/view?id=${data.uuid}`;
          this.$router.push(url);
        })
        .catch((errors) => {
          if (errors) {
            const [err] = this.parseError(errors);
            const { meta } = err;

            if (meta) {
              this.toastConfig(meta['CompanyID.Unique'], 'error');
            }
          }
        })
        .finally(() => {
          //
        });
    },
    onFileRemove() {
      this.onFileUpload = null;
      this.formData.filename = null;
      this.formData.base64 = null;
    },
    onGetPlace(place) {
      const { name, country, postal, lat, lng } = place;

      this.formData.addr1 = name;
      this.formData.country = country;
      this.formData.postal = postal;
      this.formData.latitude = parseFloat(lat || 0);
      this.formData.longitude = parseFloat(lng || 0);
    },
    onSelectGST(event) {
      const selectedGst = this.gsts.find((x) => {
        return x.item.name === event;
      });

      this.formData.gst_percent = selectedGst.item.gst_percent;
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
